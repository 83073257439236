import { collection, query, where, addDoc } from "firebase/firestore";

import { db, auth } from "../firebase/firebase";

export const getProfile = (id) => {
  const q = query(collection(db, "profiles"), where("userId", "==", id));
  return q;
};

export const addProfile = async (data) => {
  const { birthDate, constellation, gender, nickName, userId } = data;
  const result = await addDoc(collection(db, "profiles"), {
    birthDate: birthDate,
    constellation: constellation,
    gender: gender,
    nickName: nickName,
    userId: userId,
  });

  return result;
};

export const updateProfile = async (data) => {
  const { id, birthDate, constellation, gender, nickName, userId } = data;
  const docRef = db.collection("profiles").doc(data.id);
  const result = await docRef.update({
    id: id,
    birthDate: birthDate,
    constellation: constellation,
    gender: gender,
    nickName: nickName,
    userId: userId,
  });
  return result;
};

export const resetPassword = (email) => {
  auth.sendPasswordResetEmail(email, {
    // url: "https://ai-app-284bb.firebaseapp.com/password",
    url: "https://ai-uranai.jp/password",
    handleCodeInApp: true,
  });
};
