import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
import "firebase/compat/functions";

const baseUrl = window.location.protocol + "//" + window.location.host;

let firebaseConfig = {
  apiKey: "",
  authDomain: "",
  projectId: "",
  storageBucket: "",
  messagingSenderId: "",
  appId: "",
};
if (baseUrl === "https://ai-uranai.jp") {
  // プロダクション

  firebaseConfig = {
    apiKey: "AIzaSyD3qWUxhcyXOID2Yk_0DeTtsYWXs76NVYQ",
    authDomain: "ai-uranai.jp",
    projectId: "ai-app-284bb",
    storageBucket: "ai-app-284bb.appspot.com",
    messagingSenderId: "207573992551",
    appId: "1:207573992551:web:4294a7b9e662bd07e040c2",
  };
} else if (baseUrl === "https://ai-app-st.web.app") {
  // ステージング
  firebaseConfig = {
    apiKey: "AIzaSyC4Q3d69Twdko9S8WV5gWnXz2Ct-M-WuFA",
    authDomain: "ai-app-st.firebaseapp.com",
    projectId: "ai-app-st",
    storageBucket: "ai-app-st.appspot.com",
    messagingSenderId: "1041619753648",
    appId: "1:1041619753648:web:5de78bb80aebe11f6ef975",
  };
} else {
  // 本番環境
  firebaseConfig = {
    apiKey: "AIzaSyD3qWUxhcyXOID2Yk_0DeTtsYWXs76NVYQ",
    authDomain: "ai-uranai.jp",
    projectId: "ai-app-284bb",
    storageBucket: "ai-app-284bb.appspot.com",
    messagingSenderId: "207573992551",
    appId: "1:207573992551:web:4294a7b9e662bd07e040c2",
  };
  // 開発環境
  // firebaseConfig = {
  //   apiKey: "AIzaSyC4Q3d69Twdko9S8WV5gWnXz2Ct-M-WuFA",
  //   authDomain: "ai-app-st.firebaseapp.com",
  //   projectId: "ai-app-st",
  //   storageBucket: "ai-app-st.appspot.com",
  //   messagingSenderId: "1041619753648",
  //   appId: "1:1041619753648:web:5de78bb80aebe11f6ef975",
  // };
}

firebase.initializeApp(firebaseConfig);
export const provider = new firebase.auth.GoogleAuthProvider();
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
