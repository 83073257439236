import { useState, useEffect, useRef } from "react";
import { useAuthContext } from "../../context/AuthContext";
import { getDocs } from "firebase/firestore";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import { HeadBlock } from "../components/HeadBlock";
import { HeaderNotLogin } from "../components/HeaderNotLogin";
import { Footer } from "../components/Footer";
import { borderDays, constellationList } from "../../utils/constans";
import { addProfile, getProfile } from "../../hooks/query";
import firebase from "firebase/compat/app";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { toast } from "react-toastify";
import { BirthdateSelect } from "../components/BirthdateSelect";

export const Profile = () => {
  const [birthDate, setBirthDate] = useState("");
  const [gender, setGender] = useState("男性");
  const [nickName, setNickname] = useState("");
  const location = useLocation();

  const { user } = useAuthContext();

  const navigation = useNavigate();
  const { state } = location;
  const toastId = useRef(null);
  useEffect(() => {
    const profile = getProfile(user.uid);
    getDocs(profile).then((querySnapshot) => {
      if (querySnapshot.size !== 0) {
        navigation("/home");
      }
    });

    if (toastId.current) {
      toast.update(toastId.current, { autoClose: 3000 });
    } else if (state) {
      toastId.current = toast.success(state.message, {
        autoClose: 3000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendProfile = async (e) => {
    e.preventDefault();
    if (nickName === "" || birthDate === "") {
      toast.error("情報を入力してください");
      return;
    }
    const date = new Date(`${birthDate}`);
    const constellation = [];
    if (!isNaN(date)) {
      const month = date.getMonth();
      const day = date.getDate();

      // 境界日付との前後関係から星座を決定
      const borderDay = borderDays[month];
      const cIndex = day < borderDay ? month : month + 1;

      if (cIndex >= constellationList.length) {
        constellation.push(constellationList[0]); // 12月後半生まれの場合
      } else {
        constellation.push(constellationList[cIndex]);
      }
    }
    await addDoc(collection(db, "plan"), {
      plan_name: "無料プラン",
      question_times: 1,
      adding_ticket: 0,
      userId: user.uid,
      card: "",
      customer: "",
      unlimited: false,
      planId: "",
      cancel_subscription: "",
      created_at: firebase.firestore.FieldValue.serverTimestamp(),
      updated_at: firebase.firestore.FieldValue.serverTimestamp(),
    });

    await addProfile({
      birthDate: birthDate,
      constellation: constellation["0"],
      gender: gender,
      nickName: nickName,
      userId: user.uid,
    });

    navigation("/home");
  };

  if (!user) {
    return <Navigate to="/login" />;
  } else {
    return (
      <>
        <HeadBlock title="占いの前に" />
        <HeaderNotLogin />
        <div className="wrap not_login">
          <p className="center big">
            占いの前に、あなたのことを少し教えてください。
          </p>
          <form className="mt30">
            <div className="form_wrap">
              <label>ニックネーム</label>
              <input
                type="text"
                onChange={(e) => setNickname(e.target.value)}
                placeholder="全角20文字まで"
                maxLength={20}
              />
            </div>
            <div className="form_wrap">
              <label>生年月日</label>
              <BirthdateSelect onChange={setBirthDate} />
            </div>
            <div className="form_wrap">
              <label>性別</label>
              <div className="select">
                <select
                  value={gender}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="男性">男性</option>
                  <option value="女性">女性</option>
                  <option value="その他">その他</option>
                </select>
              </div>
            </div>
            <button onClick={sendProfile} className="btn_important">
              登録
            </button>
          </form>
        </div>
        <Footer />
      </>
    );
  }
};
